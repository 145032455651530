<template>
  <div class="mHome">
    <div class="mHome-top">
      <img v-lazy="topImg" alt="" srcset="">
    </div>
    <div class="mHome-concept">
      <img v-lazy="concept" alt="" srcset="">
    </div>
    <div class="mHome-whyChoose">
      <img v-lazy="whyChoose" alt="" srcset="">
    </div>
    <div class="mHome-team">
      <div class="mHome-team-top">
        <p>专业服务团队</p>
        <p>为产品提供专业且系统的系列服务</p>
      </div>
      <div class="mHome-team-center">
        <div class="mHome-team-center-top">
          <img src="../../assets/mi/home/quotes.png" alt="" srcset="">
          <p>{{ teamList[teamIndex].title }}</p>
        </div>
        <div class="mHome-team-center-bottom">
          <p>{{ teamList[teamIndex].span }}</p>
        </div>
      </div>

      <!-- element 走马灯card模式 -->
      <!-- <div class="mHome-team-bottom" id="app">
        <el-carousel :interval="4000" ref="carousel" type="card" height="289px" @change="((pre, next) => {change(pre, next)})">
          <el-carousel-item v-for="item, index in teamList" :key="index">
            <img :src="item.img" alt="" style="width: 100%; height: 100%;">
          </el-carousel-item>
        </el-carousel>
      </div> -->

      <div class="mHome-team-bottom">
        <!-- <swiper :options="swiperOptions"> -->
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item,index) in teamList" :key="index">
            <img :src="item.img" alt="" srcset="">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>

    </div>

    <div class="mHome-bottom">
      <img v-lazy="grow" alt="" srcset="">
      <router-link :to="{name:'mIntroduce'}">
        <div class="mHome-bottom-center" @click="navChange('产品介绍')">
          <p>了解更多</p>
          <img src="../../assets/mi/home/next.png" alt="" srcset="">
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['navTitle'],
  data() {
    const that = this
    return {
      topImg: require('../../assets/mi/home/top.png'),
      concept: require('../../assets/mi/home/concept.png'),
      whyChoose: require('../../assets/mi/home/whyChoose.png'),
      grow: require('../../assets/mi/home/grow.png'),

      teamList: [
        {
          title: '科学养宠',
          span: '多学科宠物专家输出养宠知识',
          img: require('../../assets/mi/home/1.png')
        },
        {
          title: '专业团队',
          span: '齐聚各大资深养宠团队指导运维',
          img: require('../../assets/mi/home/2.png')
        },
        {
          title: '一对一客服',
          span: '专业客服人员提供一对一服务',
          img: require('../../assets/mi/home/3.png')
        },
      ],
      // 轮播图索引
      teamIndex: 0,

      swiperOptions: {
        el: '.swiper-container',
        // autoplay: { disableOnInteraction: false },
        autoplay: false,
        loop: true, // 循环模式选项
        observer: true,  //开启动态检查器，监测swiper和slide
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        observeSlideChildren: true, //监测Swiper的子元素wrapper、pagination、navigation、scrollbar或其他一级子元素
        initialSlide: 1,
        // interval: 2000,
        speed: 500, // 滑动时间
        spaceBetween: 52,
        slidesPerView: 2,
        centeredSlides: true,
        slideToClickedSlide: true,
        grabCursor: true,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        mousewheel: {
          enabled: true,
        },
        keyboard: {
          enabled: true,
        },
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          transitionEnd: function () {
            that.teamIndex = this.realIndex
            // console.log('当前图片索引: ', this.activeIndex);
            // if (this.activeIndex == 0 || this.activeIndex == 3 || this.activeIndex == 6) {
            //   that.teamIndex = 1
            // } else if (this.activeIndex == 5 || this.activeIndex == 2) {
            //   that.teamIndex = 0
            // } else {
            //   that.teamIndex = 2
            // }
            // console.log(this.realIndex);
          },

          // // 手指触摸时触发
          // touchStart: function (swiper, event) {
          //   swiper.path[0].className = 'touchImgStart'
          //   console.log(swiper.path);
          // },
          // // 手指触摸结束后触发
          // touchEnd: function (swiper, event) {
          //   swiper.path[0].className = 'touchImgEnd'
          // },
          // // 按住拖动
          // touchMove: function(swiper, event) {
          //   swiper.path[0].className = 'touchImgMove'
          // }
        },
      }
    }
  },
  watch: {
    navTitle: {
      immediate: true,
      handler(newVal) {
        this.$emit('navTitleChange', newVal)
      },

    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    navChange(title) {
      this.navTitle = title
    },
    // 轮播图切换
    change(pre) {
      // console.log('当前图片索引:' + pre, '原来图片索引: ' + next);
      this.teamIndex = pre
      // this.setPY()
    },
  },
  mounted() {
    // this.slideBanner()
  }
}


</script>
<style lang="scss" scoped>
.mHome {
  .mHome-top {
    font-size: 0px;
    img {
      width: 100%;
      height: 406px;
    }
  }
  .mHome-concept {
    font-size: 0px;

    img {
      width: 100%;
      height: 500px;
    }
  }

  .mHome-whyChoose {
    font-size: 0px;
    img {
      width: 100%;
      height: 500px;
    }
  }

  .mHome-team {
    margin-bottom: 30px;
    .mHome-team-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 27.5px;
      p:first-child {
        font-family: "SourceHanSansCN-Medium";
        font-size: 23px;
        color: #333;
      }
      p:last-child {
        font-family: "SourceHanSansCN-Medium";
        font-size: 14px;
        color: #999999;
      }
    }
    .mHome-team-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      .mHome-team-center-top {
        display: flex;
        margin-bottom: 5px;

        img {
          width: 20px;
          height: 20%;
          margin-right: 5px;
        }
        p {
          font-weight: bold;
          font-size: 18px;
          color: #92d5de;
        }
      }
      .mHome-team-center-bottom {
        p {
          font-family: "SourceHanSansCN-Medium";
          font-size: 14px;
          color: #999999;
        }
      }
    }
    .mHome-team-bottom {
      margin-top: 20px;
      width: 100%;
      // height: 230px;
      img {
        width: 211px;
        height: 289px;
      }
      // /deep/ .el-carousel__item, .el-carousel__item--card, .is-in-stage {
      //   transform: translateX(310.595px) scale(0.83) !important;
      // }
      /deep/ .el-carousel__arrow,
      .el-carousel__arrow--right,
      .el-carousel__arrow--left {
        display: none;
      }

      // /deep/ .el-carousel__item
      // .el-carousel__item--card
      // .is-in-stage {
      //   transform: translateX(324.595px) scale(0.83) !important;
      // }

      // el-carousel__item el-carousel__item--card is-in-stage
      // el-carousel__item el-carousel__item--card is-in-stage

      /deep/ .el-carousel__indicators--outside button {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #92d5de;
      }
      /deep/ .swiper-container {
        // margin-top: 20px;
      }
      /deep/ .swiper-slide-prev {
        // margin-left: -30px;
        img {
        }
      }
      /deep/ .swiper-slide-active {
        img {
          height: 300px;
          margin-top: -8px;
        }
      }

      .swiper-pagination-bullets {
        // 指示器(小点)
        position: relative;
        top: 0;
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
      /deep/ .swiper-pagination-bullet-active {
        background-color: #92d5de !important;
      }
      /deep/ .swiper-pagination-bullet {
        background-color: #c6c6c6;
      }

      //异形轮播图 star
      .slideshow {
        width: 100%;
        overflow: hidden;
      }
      .swiper-container {
        width: 100%;
        // height: 7.52rem;
      }
      .swiper-wrapper {
        width: 100%;
        height: 100%;
      }
      .swiper-slide {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        font-size: 18px;
        background: #fff;
        width: 100%;
        height: 100%;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        transition: 300ms;
        transform: scale(0.88);
      }
      img {
        width: 211px;
        height: 289px;
        border-radius: 14px;
      }
      .swiper-slide-active,
      .swiper-slide-duplicate-active {
        transform: scale(1);
      }

      // 触摸后样式
      .touchImgStart {
        transform: scale(0.98);
      }
      .touchImgEnd {
        transform: scale(1);
      }
      .touchImgMove {
        transform: scale(0.8);
      }
      //异形轮播图 end
    }
  }
  .mHome-bottom {
    width: 100%;
    height: 460px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .mHome-bottom-center {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: 15px;
      justify-content: center;
      width: 100%;
      img {
        width: 5px;
        height: 9px;
      }
      p {
        font-family: SourceHanSansCN-Medium;
        // font-weight: bold;
        color: #7bc8d2;
        font-size: 12px;
        margin-right: 5px;
      }
    }
  }
}
</style>